<template>
 <div id="earning" class="play-to-earn-section landing-section">
   <div class="main-container">
     <section-title
         v-in-viewport="'fade-in-right'"
         main-label="PLAY TO"
         sub-label="WIN"
     />
     <div v-in-viewport="'fade-in-bottom'" class="info-container">
       <div class="info-box">
         <div class="info-content">
           <info-category
               category-title="RANKING"
               category-content="Finishing in the Top Positions of the Matchweek / Season."
           />
           <info-category
               category-title="PARTICIPATION"
               category-content="Join the higher Divisions and reach the targeted points."
           />
           <info-category
               category-title="ACHIEVEMENTS"
               category-content="Complete all of the Tasks and Challenges."
           />
           <info-category
               category-title="MARKETPLACE"
               category-content="Here you can sell Player Cards, Equipment, Outfits and your 12th Man."
           />
         </div>
         <div class="fantopy-button-container">
           <fantopy-button
               @click="$emit('openMenu')"
               label="LEARN MORE"
           />
         </div>

       </div>
       <div v-in-viewport="'fade-in-left'"  class="img-container">
         <img class="image-asset" src="../../assets/play-to-earn-tokens.png" alt="">
       </div>
     </div>
   </div>

 </div>
</template>

<script>
 import SectionTitle from "../newComponents/SectionTitle";
 import FantopyButton from "../newComponents/FantopyButton";
 import InfoCategory from "../newComponents/InfoCategory";
 export default {
  name: 'PlayToEarnSection',
   components: {InfoCategory, FantopyButton, SectionTitle},
 }
</script>

<style lang="scss">
@import "PlayToEarnSection.scss";
</style>