<template>

  <router-view/>

</template>

<style>

/*@import "css/_typography.scss";*/
@import "css/app.scss";

</style>

