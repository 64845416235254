<template>
  <div class="team-member">
    <div class="team-profile-picture">
      <div class="img-container">
        <img class="profile-picture" :src="profilePicture" alt="">
      </div>
    </div>

    <div class="team-member-details-container">
      <div class="member-details">
        <div class="team-member-name">{{ name }}</div>
        <div class="team-member-position">{{ position }}</div>
      </div>
      <div class="social-media-icon-container">
        <img src="../../assets/icon/linkedin.png" alt="">
      </div>
    </div>



  </div>


</template>

<script>
export default {
  name: 'TeamMember',
  props: {
    name: String,
    position: String,
    profilePicture: String,
  }

}
</script>

<style lang="scss">
@import "TeamMember";
</style>