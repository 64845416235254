import {isObject} from "../services/utils";

export default {
    created(el, binding, vnode) {
        let value = binding.value;
        let delay = 0;
        if(isObject(binding.value)) {
            value = binding.value.transition
            delay = binding.value.delay
        }
        let f = () => {
            let rect = el.getBoundingClientRect()
            let inView = (
                rect.width > 0 &&
                rect.height > 0 &&
                rect.top >= 0 &&
                (rect.bottom - ( rect.height * 0.8)) <= (window.innerHeight || document.documentElement.clientHeight)
            )
            if (inView) {
                el.classList.add(value)
                window.removeEventListener('scroll', f)
            }
        }
        if(delay) {
            el.style['transition-delay'] = `${delay}ms`
        }
        window.addEventListener('scroll', f)
        f()
    },

    mounted(el, binding) {
        let value = binding.value;
        let delay = 0;
        if(isObject(binding.value)) {
            value = binding.value.transition
            delay = binding.value.delay
        }
        el.classList.add(`${value}-before-enter`)
    }
}