<template>
  <div id="twelfthman" class="twelfth-man-section landing-section">
    <div class="main-container">
      <section-title
          v-in-viewport="'fade-in-right'"
          mainLabel="THE WILD CARD"
          sub-label="YOUR 12TH MAN"
      />
      <div v-in-viewport="'fade-in-bottom'" class="info-container">
        <div class="info-box">
          <div class="info-badge-container">

            <info-badge
                badge-label="BASE"
                :selected="textShown === 'BASE'"
                @select="textShown='BASE'"
            />

            <info-badge
                badge-label="UPGRADED"
                :selected="textShown === 'UPGRADED'"
                @select="textShown='UPGRADED'"
            />

          </div>
<!--          <Transition name="info-switch">-->
            <h6 v-if="textShown === 'BASE'" class="badge-info-content">Build your avatar and use it as your 12th Man. The total power of your 12th Man will influence and boost the score of your fantasy team.</h6>
<!--          </Transition>-->
<!--          <Transition name="info-switch">-->
            <h6 v-if="textShown === 'UPGRADED'" class="badge-info-content">You can play mini-games to improve the skills and attributes of your 12th Man. Buy, earn or bid on Equipment, Outfits and Accessories (NFTs) to upgrade specific features. </h6>
<!--          </Transition>-->
          <div class="fantopy-button-container">
            <fantopy-button
                @click="$emit('openMenu')"
                label="LEARN MORE"
                :selected="true"
            />
          </div>


        </div>
        <div v-in-viewport="'fade-in-left'" class="img-container">
          <img v-if="textShown === 'UPGRADED'" class="twelfth-man-asset" src="../../assets/twelfth-man-asset.png"
               alt="">
          <img v-if="textShown === 'BASE'" class="twelfth-man-asset" src="../../assets/twelfth-man-asset2.png" alt="">
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import SectionTitle from "../newComponents/SectionTitle";
import FantopyButton from "../newComponents/FantopyButton";
import InfoBadge from "../newComponents/InfoBadge";

export default {
  name: 'TwelfthManSection',
  components: {InfoBadge, FantopyButton, SectionTitle},
  data: () => ({
    textShown: 'BASE',
  })
}
</script>

<style lang="scss">
@import "TwelfthManSection";
</style>