<template>
  <div class="home">

    <Transition name="side-menu-mask">
      <div v-if="menuOpened"  @click="menuOpened=false" class="side-menu-mask"></div>


    </Transition>

    <Transition name="side-menu">
      <side-menu
          v-if="menuOpened"
          @closeMenu="menuOpened=false"
      />
    </Transition>

    <hero-section
        @openMenu="menuOpened=true"
    />

    <intro-section/>

    <twelfth-man-section
        @openMenu="menuOpened=true"
        ref="twelfthMan"
    />

    <marketplace-section
        @openMenu="menuOpened=true"
    />

    <play-to-earn-section
        @openMenu="menuOpened=true"
    />

    <team-structure-section/>

    <footer-section/>

  </div>


</template>

<script>
import TwelfthManSection from "../components/newSections/TwelfthManSection.vue";
import TeamStructureSection from "../components/newSections/TeamStructureSection";
import HeroSection from "../components/newSections/HeroSection";
import IntroSection from "../components/newSections/IntroSection";
import MarketplaceSection from "../components/newSections/MarketplaceSection";
import PlayToEarnSection from "../components/newSections/PlayToEarnSection";
import FooterSection from "../components/newSections/FooterSection";
import SideMenu from "../components/newComponents/SideMenu";

export default {
  name: 'Home',
  components: {
    SideMenu,
    FooterSection,
    PlayToEarnSection,
    MarketplaceSection,
    IntroSection,
    HeroSection,
    TeamStructureSection,
    TwelfthManSection,
  },

  data: () => ({
    menuOpened: false
  }),

  methods: {
    scrollTo(ref) {

    }

  }
}
</script>

<style lang="scss">
@import "Home";
</style>
