<template>
 <div class="subscribe">
   <input type="email" id="enter-email" name="email" placeholder="Enter your email adress">
   <button id="subscribe-button">SUBSCRIBE</button>

 </div>
</template>

<script>
 export default {
  name: 'Subscribe',

 }
</script>

<style lang="scss">
@import "Subscribe.scss";
</style>