<template>
  <div class="intro-step">
    <div class="title-container">
      <img class="small-icon" :src="smallIcon" alt="">
      <h4 class="intro-step-title">{{title}}</h4>
      <img class="small-icon" :src="smallIcon" alt="">
    </div>
    <h6 class="content" >{{content}}</h6>

  </div>
</template>

<script>
export default {
  name: 'IntroStep',
  props: {
    smallIcon: String,
    title: String,
    content: String,
  }

}
</script>

<style lang="scss">
@import "IntroStep.scss";
</style>