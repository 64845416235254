<template>
  <div class="footer">
    <div class="social-media-container">
      <h6>GET IN TOUCH:</h6>
      <div class="social-media-links">
        <img class="social-media-icon" src="../../assets/icon/twitter.png" alt="">
        <img class="social-media-icon" src="../../assets/icon/discord.png" alt="">
        <img class="social-media-icon" src="../../assets/icon/email.png" alt="">
      </div>

    </div>
    <div class="rights-reserved-container">
      <img src="" alt="">
      <h6>© ALL RIGHTS RESERVED 2022</h6>
    </div>
    <div class="navigate-top-icon-container">
      <img class="navigate-top-icon"  src="../../assets/icon/back-to-top.png" alt=""
           v-scroll-to="{
        el: '#top-navbar',
        container: 'body'
      }">

    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterSection',

}
</script>

<style lang="scss">
@import "FooterSection";
</style>