<template>
  <div class="marketplace-section landing-section">
    <div class="main-container">
      <section-title
          v-in-viewport="'fade-in-left'"
          main-label="WELCOME TO OUR"
          sub-label="MARKETPLACE"
      />
      <div v-in-viewport="'fade-in-bottom'"  class="info-container">
        <div class="info-box">
          <div class="info-content">
            <info-category
                category-title="BUYING & SELLING"
                category-content="On the NFT Marketplace you can buy, sell and bid on Player Cards, Equipment and Outfits."
            />
            <info-category
                category-title="SCORE CONTRIBUTION"
                category-content="Player Cards and Equipment will contribute to your overall Fantasy Team score."
            />
            <info-category
                category-title="SPECIAL ITEMS & COLLABS"
                category-content="Collect the Full Outfit or create your own style. Outfits do not contribute to your Fantasy Team Score, it's all about style points for your 12th Man."
            />
          </div>
          <div class="fantopy-button-container">
            <fantopy-button
                @click="$emit('openMenu')"
                label="LEARN MORE"
            />
          </div>

        </div>
        <div v-in-viewport="'fade-in-right'"  class="img-container">
          <img class="image-asset" src="../../assets/marketplace-ipad-1500x1500.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "../newComponents/SectionTitle";
import InfoCategory from "../newComponents/InfoCategory";
import FantopyButton from "../newComponents/FantopyButton";

export default {
  name: 'MarketplaceSection',
  components: {FantopyButton, InfoCategory, SectionTitle},
}
</script>

<style lang="scss">
@import "MarketplaceSection";
</style>