<template>
 <div class="section-title">
   <div class="main-title-container">
     <h3 class="main-title">{{mainLabel}}</h3>
   </div>
   <div class="sub-title-container">
     <h3 class="sub-title">{{subLabel}}</h3>
   </div>


 </div>
</template>

<script>
 export default {
  name: 'SectionTitle',
   props: {
    mainLabel: String,
    subLabel: String,
   }
  
 }
</script>

<style lang="scss">
@import "SectionTitle";
</style>