<template>
 <div class="info-category">
   <h5>{{categoryTitle}}</h5>
   <h6>{{categoryContent}}</h6>

 </div>
</template>

<script>
 export default {
  name: 'InfoCategory',
   props: {
     categoryTitle: String,
     categoryContent: String, 
   }

 }
</script>

<style lang="scss">
@import "InfoCategory.scss";
</style>