<template>
  <div class="hero-section landing-section">
    <nav-bar
        @openMenu="$emit('openMenu')"
    />
    <div class="main-container">
      <div class="info-container">
        <div class="info-box">
          <h1 class="title">PLAY</h1>
          <h1 class="title">OWN</h1>
          <h1 class="title">EVOLVE</h1>
          <h6 class="slogan">Fantopy is live, ready to create the next-generation fantasy sports game, powered by the blockchain!</h6>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import NavBar from "../newComponents/NavBar";
import SideMenu from "../newComponents/SideMenu";

export default {
  name: 'HeroSection',
  components: {NavBar},
}
</script>

<style lang="scss">
@import "HeroSection";
</style>