<template>
  <div class="side-menu">
    <div class="top-container">
      <div class="side-menu-header">
        <div class="header-title">LEARN MORE</div>
        <img @click="$emit('closeMenu')" class="close-side-menu" src="../../assets/icon/close-menu.png" alt="">
      </div>
      <div class="side-menu-links">
        <a class="menu-link" href="https://fantopy.gitbook.io/fantopy/introduction/fantopy-today">Whitepaper</a>
        <a class="menu-link" target="_blank" href="https://static.fantopy.com/Fantopy-PitchDeck-V1-updated.pdf">Pitchdeck</a>
        <a class="menu-link" href="https://fantopy.gitbook.io/fantopy/tokenomics/fantopy-universe">$FTY Token</a>
        <a class="menu-link" href="https://fantopy.gitbook.io/fantopy/more/roadmap">Roadmap</a>
      </div>
    </div>
    <div class="bottom-container">
      <div class="side-menu-info">
        <div class="header-title">STAY UPDATED</div>
        <div class="info">Become a Subscriber today and join our Community!</div>
      </div>
      <div class="side-menu-social">
        <subscribe/>
        <div class="social-media-links">
          <img class="social-media-asset" src="../../assets/icon/twitter-black.png" alt="">
          <img class="social-media-asset" src="../../assets/icon/discord-black.png" alt="">
          <img class="social-media-asset" src="../../assets/icon/email-black.png" alt="">
        </div>
      </div>
    </div>
    <img class="side-menu-background-asset1" src="../../assets/wireframe-ball.png" alt="">
    <img class="side-menu-background-asset2" src="../../assets/spiral.png" alt="">


  </div>
</template>

<script>
import Subscribe from "./Subscribe";

export default {
  name: 'SideMenu',
  components: {Subscribe},
}
</script>

<style lang="scss">
@import "SideMenu.scss";
</style>