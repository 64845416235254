<template>
  <div id="top-navbar" class="nav-bar">
    <div class="logo-container">
      <img class="ft-logo" src="../../assets/icon/fantopy-logo-green.png" alt="">
      <div class="ft-name">FANTOPY</div>
    </div>
    <div class="navbar-items-container">
      <a class="navbar-link" href="#" v-scroll-to="{
        el: '#twelfthman',
        container: 'body'
      }">GAMEPLAY</a>
      <a class="navbar-link" href="#" v-scroll-to="{
        el: '#earning',
        container: 'body'
      }">EARNING</a>
      <a class="navbar-link" href="#" v-scroll-to="{
        el: '#team',
        container: 'body'
      }"> THE TEAM</a>
      <plus-icon
          @click="$emit('openMenu')"
      />
    </div>
  </div>
</template>

<script>
import SideMenu from "./SideMenu";
import PlusIcon from "./PlusIcon";

export default {
  name: 'NavBar',
  components: {PlusIcon},
}
</script>

<style lang="scss">
@import "NavBar";
</style>