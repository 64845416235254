<template>
 <div class="fantopy-button">
   <button class="fantopy-button" >{{label}}</button>
   <div class="button-fill"></div>
   <div class="button-background"></div>
 </div>
</template>

<script>
 export default {
  name: 'FantopyButton',
   props: {
     label:  String,
   }
 }
</script>

<style lang="scss">
@import "FantopyButton.scss";
</style>