<template>
  <div class="plus-icon">
    <svg @click="$emit('openMenu')" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"
         width="0.444386in" height="0.444386in"
         style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
         viewBox="0 0 0.0158338 0.0158338"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="Layer_x0020_1">
  <metadata id="CorelCorpID_0Corel-Layer"/>
          <rect class="fil0" x="0.00739673" width="0.00104044" height="0.0158338"/>
          <rect class="fil0" transform="matrix(2.64845E-014 -1 1 2.64845E-014 -0 0.00843704)" width="0.00104044"
                height="0.0158338"/>
 </g>
</svg>

  </div>
</template>

<script>
export default {
  name: 'PlusIcon',

}
</script>

<style lang="scss">
@import "PlusIcon.scss";
</style>