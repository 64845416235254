<template>
 <div @click="$emit('select')" class="info-badge" :class="{'selected':selected}">
   <h5>{{ badgeLabel }}</h5>

 </div>
</template>

<script>
 export default {
  name: 'InfoBadge',
   props:{
     badgeLabel: String,
     selected: Boolean,
   }

 }
</script>

<style lang="scss">
@import "InfoBadge.scss";
</style>