import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import inViewport from "./directives/inViewport";

createApp(App)
    .use(store)
    .use(router)
    .use( VueScrollTo, {duration: 600})
    .directive('in-viewport', inViewport)
    .mount('#app')
