<template>
  <div class="intro-section landing-section">
    <div class="main-container">
      <div class="info-box">
        <h4
            v-in-viewport="'fade-in-bottom'"
            class="intro-text"
        >
          Explore a game of Skill and Strategy. Our blockchain-integrated platform will offer the first Metaverse
          fantasy game on the continent.
        </h4>
        <div class="steps-container">
          <intro-step
              v-in-viewport="{
                transition: 'fade-in-bottom',
                delay: 0
              }"
              :smallIcon="greenStar"
              title="PLAY"
              content="Create your Fantasy Dream Team. Become a master Tactician and triumph against your opponents."
          />
          <intro-step
              v-in-viewport="{
                transition: 'fade-in-bottom',
                delay: 200
              }"
              :smallIcon="purpleStar"
              title="OWN"
              content="Invest in your own Avatar who will be your 12th Man. Trade and swap game assets for exclusive rewards."
          />
          <intro-step
              v-in-viewport="{
                transition: 'fade-in-bottom',
                delay: 400
              }"
              :smallIcon="greenStar"
              title="EVOLVE"
              content="Recover between matches by playing our mini-games and boosting your 12th man attributes. NFTs develop while the game progresses."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IntroStep from "../newComponents/IntroStep";

export default {
  name: 'IntroSection',
  components: {IntroStep},
  data: () => ({
    greenStar: require('../../assets/icon/green-star.png'),
    purpleStar: require('../../assets/icon/purple-star.png')
  })

}
</script>

<style lang="scss">
@import "IntroSection";
</style>