<template>
  <div class="team-member-grid">

    <team-member
        name="COEN VAN DER VOORT"
        position="CEO & Founder"
        :profile-picture="coen"
    />

    <team-member
        name="KLIMENT LAMBEVSKI"
        position="CTO"
        :profile-picture="kliment"
    />

    <team-member
        name="ANJA TOMOVSKA"
        position="Blockchain Developer"
        :profile-picture="anja"
    />

    <team-member
        name="CHAOWARIN MACHUAY"
        position="Operations Thailand"
        :profile-picture="chaowarin"
    />

    <team-member
        name="TEAM JAKARTA"
        position="Operations Indonesia"
        :profile-picture="jakarta"
    />

    <team-member
        name="BOJANA TRAJKOVSKA"
        position="Software Engineer"
        :profile-picture="bojana"
    />

    <team-member
        name="MIGUEL CARRAÇA"
        position="Lead Designer"
        :profile-picture="miguel"
    />

    <team-member
        name="OGNEN TRAJKOVSKI"
        position="UI/UX Developer"
        :profile-picture="ognen"
    />

    <team-member
        name="SLAVKO KAZAKOV"
        position="Backend Developer "
        :profile-picture="slavko"
    />

    <team-member
        name="NINA BALABAN"
        position="QA"
        :profile-picture="nina"
    />

<!--    <team-member-->
<!--        name="team"-->
<!--        position="team-role"-->
<!--    />-->

    <team-member
        name="TIJANA DENKOVSKA"
        position="Product Manager"
        :profile-picture="tijana"
    />

    <team-member
        name="BART VAN DER VOORT"
        position="Advisory Support"
        :profile-picture="bart"
    />

    <team-member
        name="ERIK MAYER"
        position="Advisory Support"
        :profile-picture="erik"
    />

    <team-member
        name="JOE MOORE"
        position="Advisory Support"
        :profile-picture="joe"
    />

    <team-member
        name="PREMIER"
        position="Advisory Support"
        :profile-picture="partner"
    />

  </div>
</template>

<script>
import TeamMember from "./TeamMember";

export default {
  name: 'TeamMemberGrid',
  components: {TeamMember},
  data: ()=>({
    anja: require('../../assets/team-profile-pictures/anja-tomovska.jpg'),
    bart: require('../../assets/team-profile-pictures/bart-van-der-voort.jpg'),
    bojana: require('../../assets/team-profile-pictures/bojana-trajkovska.jpg'),
    chaowarin: require('../../assets/team-profile-pictures/chaowarin-machuay.jpg'),
    coen: require('../../assets/team-profile-pictures/coen-van-der-voort.jpg'),
    kliment: require('../../assets/team-profile-pictures/kliment-lambevski.jpg'),
    nina: require('../../assets/team-profile-pictures/nina-balaban.jpg'),
    ognen: require('../../assets/team-profile-pictures/ognen-trajkovski.jpg'),
    slavko: require('../../assets/team-profile-pictures/slavko-kazakov.jpg'),
    jakarta: require('../../assets/team-profile-pictures/team-jakarta.jpg'),
    tijana: require('../../assets/team-profile-pictures/tijana-denkovska.jpg'),
    miguel: require('../../assets/team-profile-pictures/miguel-carraca.jpg'),
    erik: require("../../assets/team-profile-pictures/erik-mayer.jpg"),
    joe: require("../../assets/team-profile-pictures/joe-moore.jpg"),
    partner: require('../../assets/team-profile-pictures/advisor-partner.jpeg')
  })
}
</script>

<style lang="scss">
@import "TeamMemberGrid";
</style>