<template>
  <div id="team" class="team-structure-section landing-section">
    <div class="main-container">
      <section-title
          main-label="THE"
          sub-label="TEAM"
      />
      <div class="info-container">
        <div class="info-content">
          <h6 class="team-info">We are Fantopy, an experienced Game Studio based in Asia and Europe, on a mission to
            create exciting and challenging Fantasy Games.</h6>
        </div>
      </div>
      <div class="team-member-container">
        <team-member-grid/>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "../newComponents/SectionTitle";
import TeamMemberGrid from "../newComponents/TeamMemberGrid";

export default {
  name: 'TeamStructureSection',
  components: {TeamMemberGrid, SectionTitle},
}
</script>

<style lang="scss">
@import "TeamStructureSection";
</style>